import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Container, NavContainer, NavButton } from './styles';

export default function Menu({ color }) {
  const [oppened, setOppened] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setOppened(false);
  }, [pathname]);

  useEffect(() => {
    const mainContent = document.getElementById('main-content');
    if (!mainContent) return;

    if (oppened) {
      mainContent.classList.add('open');
    } else {
      mainContent.classList.remove('open');
    }
  }, [oppened]);

  return (
    <>
      <NavButton
        color={color}
        open={oppened}
        onClick={() => setOppened(!oppened)}
      >
        {oppened ? <FaTimes /> : <FaBars />}
      </NavButton>

      <Container open={oppened}>
        <NavContainer>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/sobre">Sobre</Link>
            </li>
            <li>
              <Link to="/portifolio">Portifólio</Link>
            </li>
            <li>
              <Link to="/contato">Contato</Link>
            </li>
          </ul>
        </NavContainer>
      </Container>
    </>
  );
}

Menu.propTypes = {
  color: PropTypes.string,
};

Menu.defaultProps = {
  color: 'secondary',
};
