import styled from 'styled-components';

export const TextContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  padding: 0 25px 0 0;
  margin-top: 30px;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.secondary};
    border: 0px none ${({ theme }) => theme.colors.secondary};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.primary};
    border: 0px none ${({ theme }) => theme.colors.secondary};
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
  ::-webkit-scrollbar-track:active {
    background: ${({ theme }) => theme.colors.primary};
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
