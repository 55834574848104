export default function getImagePath(html) {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;

  const image = tmp.querySelector('img');

  if (image) {
    return image.getAttribute('src');
  }

  return `https://picsum.photos/640/480?random=${new Date().getTime()}`;
}
