import React from 'react';
import { Link } from 'react-router-dom';

import Button from '~/elements/Button';
import Title from '~/elements/Title';

import { TextContainer } from './styles';

export default function Sobre() {
  return (
    <>
      <Title>Selma Barbosa.</Title>

      <TextContainer>
        <p>
          Tenho 22 anos, sou bacharel em Jornalismo pelo Centro Universitário
          FIAM-FAAM, Operadora de Câmera pelo Senac (DRT nº 0055792/SP) e aluna
          de Produção de Áudio e Vídeo na ETEC Jornalista Roberto Marinho.
          Trabalho na área de comunicação desde 2018, com experiências de
          estágio no Ministério Público do Trabalho e no Metrô de São Paulo.
        </p>
        <p>
          Atuo como freelancer produzindo conteúdo para redes sociais e blogs
          como copywriter e ghostwriter, além de alguns trabalhos esporádicos
          com design gráfico. No momento em tempo integral sou Social Media da
          agência de comunicação 8ponto3, onde redijo posts otimizados para SEO,
          planejo, crio conteúdo e gerencio redes sociais pelo Postgrain e
          Hootsuite, trabalhando no marketing digital de empresas do segmento de
          luxo, entre elas Casa Petra, 1-18 Project e Perfectrip.
        </p>
        <p>
          No audiovisual, tenho algumas experiências acadêmicas, com ficção e
          não-ficcional, de curta-metragem à povo fala. Atualmente também estou
          aprendendo fotografia e tenho postado todos os ensaios que faço no meu
          Intagram profissional @macamifotografia.
        </p>
        <p>Escrevo, diagramo, filmo e fotografo. Veja meu portfólio abaixo:</p>

        <p>
          <Button as={Link} to="/portifolio">
            Veja meu trabalho
          </Button>
        </p>
      </TextContainer>
    </>
  );
}
