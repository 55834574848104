import React from 'react';

import PropTypes from 'prop-types';

import foto from '~/assets/profile.png';
import Menu from '~/components/Menu';
import SocialMediaList from '~/components/SocialMediaList';

import {
  Container,
  InfoContainer,
  FotoContainer,
  Foto,
  HomeStyle,
} from './styles';

export default function HomeLayout({ children }) {
  return (
    <>
      <div id="main-content" style={{ height: '100vh' }}>
        <Container>
          <InfoContainer>{children}</InfoContainer>
          <FotoContainer>
            <Foto src={foto} alt="Selma Barbosa" />
            <SocialMediaList />
          </FotoContainer>
        </Container>
      </div>
      <Menu />
      <HomeStyle />
    </>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
