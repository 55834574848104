export const configFonts = [
  {
    font: 'Poppins',
    weights: [400, 500, 600],
  },
  {
    font: 'PT Serif',
    weights: [400],
  },
];

export default {};
