import React from 'react';

import PropTypes from 'prop-types';

import Menu from '~/components/Menu';

export default function DefaultLayout({ children }) {
  return (
    <>
      <div id="main-content">{children}</div>
      <Menu color="primary" />
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
