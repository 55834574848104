import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    font: 15px 'PT Serif';
    @media (max-width: 900px) {
      height: auto;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }

  div.pro-gallery .gallery-item-container:not(.invert-hover) .gallery-item-hover.force-hover {
    &:before {
      background: transparent !important;
    }
  }

  .gallery-item-hover-inner {
    height: 100%;
    display: flex;
  }

  .page-container {
    text-align: justify;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
  }
  figure.wp-block-image {
    text-align: center;
    margin-bottom: 20px;
  }
  img {
    max-width: 100%;
  }

  #root, #main-content {
    transition: all 0.5s;
  }

  #main-content {
    &.open {
      transform: translateX(-30%);
    }
  }

  @media (min-width: 576px) {}
`;
