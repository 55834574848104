import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 30%;
  position: fixed;
  display: block;
  top: 0;
  right: ${({ open }) => (open ? 0 : '-30%')};
  transition: all 0.5s;
`;

export const Main = styled.div`
  width: 70%;
  padding-top: 15%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent 10%,
    ${({ theme }) => theme.colors.secondary} 10% 30%,
    transparent 30% 100%
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    height: 80vh;
    margin-right: 30px;
    align-self: flex-end;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const NavContainer = styled.nav`
  height: 100%;
  /* width: 30%; */
  /* padding-top: 15%; */
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  ul {
    flex: 1;
    list-style: none;
    margin-left: 50px;
    li {
      margin: 20px 0;
      border-bottom: 10px solid rgba(255, 255, 255, 0.2);
      a {
        font-size: 30px;
        font-family: Poppins;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.secondary};
        text-decoration: none;
        transition: all 0.2s ease;
        &:hover {
          color: ${({ theme }) => theme.colors.secondaryHover};
        }
      }
    }
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const NavButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 30px;
  right: 30px;
  background: transparent;
  border: none;
  font-size: 30px;
  color: ${({ theme, color, open }) =>
    color === 'primary' && !open
      ? theme.colors.primary
      : theme.colors.secondary};
  cursor: pointer;
  z-index: 100;
`;
