import React from 'react';
import { Link } from 'react-router-dom';

import Button from '~/elements/Button';
import Subtitle from '~/elements/Subtitle';
import Title from '~/elements/Title';

export default function Home() {
  return (
    <>
      <Title>Sou Selma Barbosa.</Title>
      <Subtitle>Jornalista.</Subtitle>
      <p>
        Sou bacharel em Jornalismo pelo Centro Universitário FIAM-FAAM,
        Operadora de Câmera pelo Senac e aluna de Produção de Áudio e Video na
        ETEC Jornalista Roberto Marinho. Escrevo, diagramo, filmo e fotografo.
      </p>
      <p>
        <Button as={Link} to="/portifolio">
          Veja meu trabalho
        </Button>
      </p>
    </>
  );
}
