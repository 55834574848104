import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'pro-gallery/dist/statics/main.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { ThemeProvider } from 'styled-components';

import Routes from '~/routes';
import GlobalStyle from '~/styles';
import theme from '~/styles/theme';
import { configFonts } from '~/styles/theme/fonts';

import 'axios-progress-bar/dist/nprogress.css';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <GlobalStyle />
      <ToastContainer />
      <GoogleFontLoader fonts={configFonts} />
    </ThemeProvider>
  );
}
