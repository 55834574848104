import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Contato from '~/pages/Contato';
import Home from '~/pages/Home';
import Portifolio from '~/pages/Portifolio';
import PortifolioDetalhes from '~/pages/PortifolioDetalhes';
import Sobre from '~/pages/Sobre';

import Route from './Route';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact useLayout="home" />
        <Route path="/sobre" component={Sobre} exact useLayout="home" />
        <Route
          path="/portifolio"
          component={Portifolio}
          exact
          useLayout="default"
        />
        <Route
          path="/portifolio/:id/:slug"
          component={PortifolioDetalhes}
          exact
          useLayout="default"
        />
        <Route path="/contato" component={Contato} exact useLayout="default" />
      </Switch>
    </BrowserRouter>
  );
}
