import { lighten } from 'polished';
import styled from 'styled-components';

export const Background = styled.div`
  margin: 90px 0 30px 0;
  padding: 10px;
  background: ${({ theme }) => theme.colors.secondary};
  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    li {
      text-align: center;
      a {
        text-align: center;
        font-size: 1.5rem;
        font-family: 'Poppins';
        font-weight: 600;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.primary};
        transition: all 0.2s ease;
        &:hover {
          color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
          transition: all 0.2s ease;
        }
      }
    }
  }
  h1 {
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
  }
  @media (max-width: 900px) {
    ul {
      flex-direction: column;
      li {
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }
`;
