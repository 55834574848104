import styled from 'styled-components';

export const Container = styled.div`
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    li {
      margin-bottom: 10px;
      a {
        width: 60px;
        height: 60px;
        display: flex;
        font-size: 30px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.2s ease;
        background: ${({ theme, color }) => theme.colors[color]};
        color: ${({ theme, color }) =>
          color === 'primary' ? '#fff' : theme.colors.primary};
        &:hover {
          background: ${({ theme, color }) => theme.colors[`${color}Hover`]};
        }
      }
    }
  }
`;
