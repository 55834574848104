/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';

import DefaultLayout from '~/pages/_layouts/default';
import HomeLayout from '~/pages/_layouts/home';

export default function RouteWrapper({
  component: Component,
  useLayout,
  ...rest
}) {
  let Layout = null;

  if (useLayout === 'home') {
    Layout = HomeLayout;
  } else if (useLayout !== '') {
    Layout = DefaultLayout;
  } else {
    Layout = React.Fragment;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  useLayout: PropTypes.oneOf(['default', 'home']),
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  useLayout: 'default',
};
