import React from 'react';

// import { Container } from './styles';

export default function Contato() {
  return (
    <div>
      <h1>Contato</h1>
    </div>
  );
}
