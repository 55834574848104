import React, { useEffect, useState, useRef, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

import Header from '~/components/Header';
import Container from '~/elements/Container';
import api from '~/services/api';
import theme from '~/styles/theme';

import getImagePath from '../../helpers/getImagePath';
import Galeria from './Galeria';

function generateItems(result) {
  const items = [];

  result.forEach(post => {
    items.push({
      itemId: `post-${post.id}`,
      mediaUrl: getImagePath(post.content.rendered),
      metaData: {
        type: 'image',
        height: 480,
        width: 640,
        title: post.title.rendered,
        focalPoint: [0, 0],
        link: {
          url: `/portifolio/${post.id}/${post.slug}`,
          target: '_self',
        },
      },
    });
  });

  return items;
}

export default function Portifolio() {
  const [categorias, setCategorias] = useState([]);
  const [items, setItems] = useState([]);
  const [portifolios, setPortifolios] = useState([]);
  const containerRef = useRef();

  const fetch = useCallback(() => {
    async function loadContent() {
      try {
        const [categoriasResponse, postsResponse] = await Promise.all([
          api.get('/categories'),
          api.get('/posts'),
        ]);
        const generate = generateItems(postsResponse.data);

        setCategorias(categoriasResponse.data);
        setPortifolios(postsResponse.data);
        setItems(generate);
      } catch (e) {
        toast.error('Não foi possível realizar a operação');
      }
    }

    loadContent();
  }, []);

  useEffect(fetch, []);

  function getWidthContainer() {
    return containerRef.current ? containerRef.current.offsetWidth - 30 : 1000;
  }

  function handleFilterPortifolio(category) {
    setItems([]);
    setTimeout(() => {
      const results = portifolios.filter(item => {
        return category ? item.categories.indexOf(category) >= 0 : true;
      });

      setItems(generateItems(results));
    }, 500);
  }

  return (
    <>
      {categorias.length ? (
        <Header>
          <ul>
            {categorias.map(categoria => (
              <li key={categoria.name}>
                <a
                  href={`#${categoria.slug}`}
                  onClick={() => handleFilterPortifolio(categoria.id)}
                >
                  {categoria.name}
                </a>
              </li>
            ))}
          </ul>
        </Header>
      ) : null}
      <Container ref={containerRef}>
        {items.length ? (
          <Galeria items={items} width={getWidthContainer()} />
        ) : (
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              marginTop: 100,
            }}
          >
            <Loader
              type="ThreeDots"
              color={theme.colors.primary}
              height={80}
              width={80}
            />
          </div>
        )}
      </Container>
    </>
  );
}
