import styled from 'styled-components';

import imgCornerBottomRight from '../../assets/corner-bottom-right.png';
import imgCornerTopLeft from '../../assets/corner-top-left.png';

export const Container = styled.div`
  margin: 15px;
  padding: 10px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 0.75);
  border: 10px solid ${({ theme }) => theme.colors.secondary};
  font-family: 'Anonymous Pro';
  position: relative;
  a {
    color: #000000;
    text-decoration: none;
  }
  &:before,
  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
  }
  &:before {
    background: url(${imgCornerTopLeft});
    top: -10px;
    left: -10px;
  }
  &:after {
    background: url(${imgCornerBottomRight});
    bottom: -10px;
    right: -10px;
  }
`;
