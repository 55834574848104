import { linearGradient } from 'polished';
import styled from 'styled-components';

const Button = styled.button`
  font-size: 20px;
  font-family: Poppins;
  font-weight: bold;
  text-decoration: none;
  padding: 3px 10px;
  color: ${({ theme }) => theme.colors.primary};
  transition: all 0.2s ease;
  ${({ theme }) =>
    linearGradient({
      colorStops: [
        `transparent 0%`,
        `transparent 50%`,
        `${theme.colors.secondary} 50%`,
        `${theme.colors.secondary} 100%`,
      ],
      toDirection: 'to bottom',
      fallback: '#FFF',
    })};
  &:hover {
    ${({ theme }) =>
      linearGradient({
        colorStops: [
          `transparent 0%`,
          `transparent 50%`,
          `${theme.colors.secondaryHover} 50%`,
          `${theme.colors.secondaryHover} 100%`,
        ],
        toDirection: 'to bottom',
        fallback: '#FFF',
      })};
  }
`;

export default Button;
