import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  max-width: 90%;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InfoContainer = styled.div`
  width: 60%;
  position: relative;
  padding: 50px;
  align-self: flex-end;
  min-height: 665px;
  &::before,
  &::after {
    content: '';
    display: block;
    background: ${({ theme }) => theme.colors.primary};
    position: absolute;
    top: 0;
    left: 0;
  }
  &::before {
    width: 40px;
    height: 8px;
  }
  &::after {
    height: 40px;
    width: 8px;
  }
  p {
    font-size: 20px;
    text-align: justify;
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 6rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 5rem;
`;

export const Subtitle = styled.h2`
  font-size: 5rem;
  font-family: Poppins;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const FotoContainer = styled.div`
  /* flex: 1; */
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 900px) {
    width: 100%;
    justify-content: center;
    flex-direction: column-reverse;
    ul {
      flex-direction: row;
      li {
        margin: 20px 10px;
      }
    }
  }
`;

export const Foto = styled.img`
  max-width: 90%;
  margin-right: 30px;
  @media (max-width: 900px) {
    max-width: 80%;
  }
`;

export const HomeStyle = createGlobalStyle`
  body {
    background: linear-gradient(to bottom, transparent 10%, ${({ theme }) =>
      theme.colors.secondary} 10% 30%, transparent 30% 100%) no-repeat;
      @media (max-width: 900px) {
        background: linear-gradient(to bottom, transparent 10%, ${({ theme }) =>
          theme.colors.secondary} 10% 20%, transparent 20% 100%) no-repeat;
      }
  }

  #main-content {
    background: linear-gradient(to right, transparent 70%, ${({ theme }) =>
      theme.colors.primary} 70% 100%) repeat;
      @media (max-width: 900px) {
        background: linear-gradient(to right, transparent 90%, ${({ theme }) =>
          theme.colors.primary} 90% 100%) repeat;
      }
  }

`;
