import axios from 'axios';
import { loadProgressBar } from 'axios-progress-bar';

const api = axios.create({
  baseURL: 'https://api.selmabarbosa.com/wp-json/wp/v2',
});

loadProgressBar({}, api);

export default api;
