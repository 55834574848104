import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function GaleryHover({ item }) {
  const { title, linkUrl } = item;

  return (
    <Container>
      <Link to={linkUrl}>{title}</Link>
    </Container>
  );
}
