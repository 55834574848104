import React from 'react';
import PropTypes from 'prop-types';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaBloggerB,
} from 'react-icons/fa';

import { Container } from './styles';

export default function SocialMediaList(props) {
  return (
    <Container {...props}>
      <ul>
        <li>
          <a
            href="https://www.facebook.com/SelmaCamilaBarbosa"
            title="Facebook"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaFacebookF />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/selmacbarbosa/"
            title="Instagram"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/selma-barbosa/"
            title="Linkedin"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaLinkedinIn />
          </a>
        </li>
        <li>
          <a
            href="https://contopaulistano.blogspot.com/"
            title="Blog"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FaBloggerB />
          </a>
        </li>
      </ul>
    </Container>
  );
}

SocialMediaList.propTypes = {
  color: PropTypes.string,
};

SocialMediaList.defaultProps = {
  color: 'secondary',
};
