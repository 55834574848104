import styled from 'styled-components';

const Subtitle = styled.h2`
  font-size: 5rem;
  font-family: Poppins;
  font-weight: 500;
  margin-bottom: 20px;
`;

export default Subtitle;
