import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

import Header from '~/components/Header';
import Container from '~/elements/Container';
import api from '~/services/api';
import theme from '~/styles/theme';

export default function PortifolioDetalhes({ match }) {
  const postId = match.params.id;

  const [portifolio, setPortifolio] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  async function fetch() {
    try {
      const { data } = await api.get(`posts/${postId}`);
      setPortifolio(data);
    } catch (e) {
      toast.error('Não foi possível realizar a operação');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          marginTop: 100,
        }}
      >
        <Loader
          type="ThreeDots"
          color={theme.colors.primary}
          height={80}
          width={80}
        />
      </div>
    );
  }

  return (
    <>
      <Header>
        <h1>{portifolio.title.rendered}</h1>
      </Header>
      <Container>
        <div
          className="page-container"
          dangerouslySetInnerHTML={{ __html: portifolio.content.rendered }}
        />
      </Container>
    </>
  );
}
