import React from 'react';

import Container from '~/elements/Container';

import { Background } from './styles';

export default function Header({ children }) {
  return (
    <Background>
      <Container>{children}</Container>
    </Background>
  );
}
