import React from 'react';

import { ProGallery } from 'pro-gallery';

import GaleryHover from '../../components/GaleryHover';

export default function Galeria({ items, width }) {
  // The options of the gallery (from the playground current state)
  const options = {
    galleryLayout: 1,
    imageMargin: 40,
    gallerySize: 50,
    allowHover: true,
    allowTitle: true,
    allowDescription: true,
    enableInfiniteScroll: true,
    loadMoreAmount: 'all',
    hoveringBehaviour: 'APPEARS',
    itemClick: 'expand',
    galleryHorizontalAlign: 'center',
    galleryVerticalAlign: 'center',
    titleDescriptionSpace: 6,
    overlayAnimation: 'EXPAND',
    imageHoverAnimation: 'ZOOM_IN',
    imageLoadingMode: 'BLUR',
    scrollAnimation: 'FADE_IN',
    imageQuality: 90,
    videoPlay: 'hover',
    videoSpeed: '1',
    videoLoop: true,
    allowSocial: false,
    loveButton: false,
  };

  // The size of the gallery container. The images will fit themselves in it
  const container = {
    width,
    height: window.innerHeight,
  };

  return (
    <ProGallery
      items={items}
      options={options}
      container={container}
      customHoverRenderer={itemProps => <GaleryHover item={itemProps} />}
    />
  );
}

// Enjoy using your new gallery!
// For more options, visit https://github.com/wix-incubator/pro-gallery
